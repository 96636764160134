<template>
	<div class="index" >

		<div class="index2">
			<!-- <p style="margin-bottom: 80px;">{{$t('top.DepositWithdrawTishi')}}</p> -->
			<div style="margin: 10px 0;" class="re_from">
				<div style="text-align: center;">用户中心</div>
				<div>
					<label class="re_laber_input">{{$t('notify.account')}}</label>
					<div><div class="re_input2">{{username}}</div></div>
				</div>
				<div>
					<label class="re_laber_input">{{$t('notify.balance')}}</label>
					<div><div class="re_input2">{{money}}</div></div>
				</div>
				<div style="display: flex;justify-content: center;">
					<div class="btn_small_1" @click="tixian()"><span>提现金额</span></div>
					<!-- <button class="btn_blue_class" style="margin: 10px 0;max-width: 500px;" @click="tixian()"><span>提现金额</span></button> -->
				</div>
				<!-- <div  style="display: flex;justify-content: center;">
					<div class="btn_medium_2" @click="getDepositAddress()"><span>充值</span></div>
					
				</div> -->
				<div>
					<label class="re_laber_input">折扣余额</label>
					<div><div class="re_input2">{{discount_amount}}</div></div>
				</div>
				<div>
					<label for="deposit-input" class="re_laber_input">{{$t('notify.code')}}</label>
					<div><div class="re_input2">{{invite_code}}</div></div>
				</div>
				<div style="display: flex;justify-content: center;">
					<div class="btn_small_1" @click="copyCode()"><span>复制邀请码链接</span></div>
					<!-- <button class="btn_blue_class" style="margin: 10px 0;max-width: 500px;" @click="copyCode()"><span>{{$t('user.copyCode')}}</span></button> -->
				</div>
				<div>
					<label class="re_laber_input">{{$t('notify.address')}}</label>
					<div><input type="text" placeholder="Enter address" class="re_input" v-model="wallet_address" /></div>
				</div>
				
			</div>
			<div style="display: flex;justify-content: center;">
				<div class="btn_small_1" @click="bindAddress()"><span>绑定钱包地址</span></div>
				<!-- <button class="btn_blue_class" style="margin: 10px 0;max-width: 500px;" @click="bindAddress()"><span>{{$t('user.bindAddress')}}</span></button> -->
			</div>
			

			
			
			<div  style="display: flex;justify-content: center;">
				<div class="btn_medium_2" @click="getDepositAddress()"><span>获取充值地址</span></div>
				<!-- <button class="btn_blue_class" style="margin: 10px 0;max-width: 500px;" @click="getDepositAddress()"><span>获取充值地址</span></button> -->
			</div>
			<div v-if="cz_address != ''">
				<label class="re_laber_input">{{$t('notify.czaddress')}}</label>
				<div><div style="word-wrap:break-word;word-break:normal; width: 100%;font-size: 14px;">{{cz_address}}</div></div>
			</div>
			<div v-if="cz_address != ''" style="display: flex;justify-content: center;">
				<div class="btn_small_1" @click="copyCode2()"><span>复制充值地址</span></div>
				<!-- <button class="btn_blue_class" style="margin: 10px 0;max-width: 500px;" @click="copyCode2()"><span>复制充值地址</span></button> -->
			</div>
			<div style="display: flex;justify-content: center;">
				<div class="btn_small_2" @click="back()"><span>查看收益</span></div>
				<!-- <button class="btn_blue_class" style="margin: 10px 0;max-width: 500px;" @click="back()"><span>返回首页</span></button>		 -->
			</div>
			<div style="display: flex;justify-content: center;">
				<div class="btn_small_2" @click="outlogin()"><span>退出登录</span></div>
				<!-- <button class="btn_blue_class" style="margin: 10px 0;max-width: 500px;" @click="outlogin()"><span>{{$t('user.outlogin')}}</span></button> -->
			</div>
			

			
		
		
		</div>

	</div>
</template>

<script>
	import request from '@/utils/request'
	export default {
		name: 'User',
		data() {
			return {
				wallet_address:'',
				invite_code:'',//invite_code
				username:'',
				money:0,
				discount_amount:0,
				user_info:[],
				cz_address:'',

			};
		},
		activated() {
			//console.log('我这个页面显示就会执行');
			let _this = this;
			//刷新
			if (_this.$route.params.refresh) {
				_this.$router.go(0)
			}
			_this.local_data();
			
		},
		mounted() {
			let _this = this;
		},

		methods: {
			//获取信息
			local_data(){
				let _this = this;
				let token = window.sessionStorage.getItem('token');
				if (token == null || token == '' || token == 'null' || token == undefined || token == 'undefined') {
					this.hasLogin = 0;
					window.sessionStorage.clear();
					this.$router.replace({ name: 'Login', params: { refresh: true }})
				}else{
					this.hasLogin = 1;
					_this.account();
					
				}
				
				
			},

			account(){
				let _this = this;
				let token = window.sessionStorage.getItem('token');
				request.post('finance/user/index', {
					token: token,
				}).then(res => {
					//console.log(res)
					//结果
					if(res.data.code ==0){
						window.sessionStorage.setItem('user_info',JSON.stringify(res.data.data));
						_this.user_info = JSON.parse(window.sessionStorage.getItem('user_info'));
						_this.invite_code = _this.user_info.invite_code;
						_this.wallet_address = _this.user_info.wallet_address;
						_this.username = _this.user_info.username;
						_this.money = _this.user_info.money;
						_this.discount_amount = _this.user_info.discount_amount;
						
					}else{
						_this.$message({
						    message: _this.$t('disc.Please log in'),
						    type: 'warning'
						});
					}
				});
			},
			//绑定钱包地址
			bindAddress(){
				let _this = this;
				let wallet = _this.wallet_address;
				if(_this.wallet_address == ''){
					_this.$message({
					    message: '请输入钱包地址',
					    type: 'success'
					});
				}else{
					let token = window.sessionStorage.getItem('token');
					request.post('finance/user/bindWallet', {
						token:token,
						wallet:wallet,
					}).then(res => {
						//console.log(res)
						if(res.data.code == 0){
							_this.$message({
							    message: _this.$t('notify.savesuccess'),
							    type: 'success'
							});
							
							
							
						}else if(res.data.code == 1000){
							
							_this.$message({
								message: res.data.msg,
								type: 'warning'
							});
							
						}else {
							_this.$message({
							    message: res.data.msg,
							    type: 'warning'
							});
						}
						
					});
				}
				
			},
			//获取充值地址
			getDepositAddress(){
				let _this = this;
				let wallet_address = _this.wallet_address;
				if(_this.wallet_address == ''){
					_this.$message({
					    message: '请先绑定钱包地址',
					    type: 'warning'
					});
				}else{
					let token = window.sessionStorage.getItem('token');
					request.post('finance/payment/getWalletAddress', {
						token:token,
					}).then(res => {
						//console.log(res)
						if(res.data.code == 0){
							_this.cz_address = res.data.data.wallet;
						}else if(res.data.code == 1000){
							
							_this.$message({
								message: res.data.msg,
								type: 'warning'
							});
							
						}else{
							
							_this.$message({
							    message: res.data.msg,
							    type: 'warning'
							});
						}
						
					});
				}
				
			},
			
			// 复制邀请码
			copyCode() {
				let _this = this;
				let invite_code = _this.invite_code;
				let url = 'https://www.blockmoneychain.com/#/register?code='+invite_code;
				//console.log(url)
				_this.$copyText(url).then(
					res => {
					    //console.log(res)
						_this.$message({
						    message: 'Copy Success',
						    type: 'success'
						});
					},
					err => {
					    this.$message.error('error');
					})
			},
			// 复制充值地址
			copyCode2() {
				let _this = this;
				let user_code = _this.cz_address;
				
				_this.$copyText(user_code).then(
					res => {
					    //console.log(res)
						_this.$message({
						    message: 'Copy Success',
						    type: 'success'
						});
					},
					err => {
					    this.$message.error('error');
					})
			},
			//退出登录用户
			outlogin(){
				let _this = this;
				window.sessionStorage.clear();
				this.$router.replace({ name: 'Login', params: { refresh: true }})
			},
			//返回首页
			back(){
				this.$router.replace({ name: 'Index', params: { refresh: true }})
			},
			
			//提现金额
			tixian(){
				this.$router.replace({ name: 'User_withdraw', params: { refresh: true }})
			},
			





		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
	.index{
		
		background:url(../assets/images/blockmoney/bg.png);
		background-size: cover;
		background-position: 50%;
		/* height: calc(100vh - 60px); */
		display: flex;
		justify-content: center;
		/* align-items: center; */
	}
	.index2{
		border: 2px solid #6DB0C6;
		border-radius: 5px;
		background: linear-gradient(180deg, #0C4781, #051022 30%);
		padding: 20px 50px;
		width: 90%;
		max-width: 600px;
		/* height: 320px; */
		margin-top: 50px;
		margin-bottom: 50px;
		
	}
	.btn_blue_class {
		-webkit-box-align: center;
		align-items: center;
		border: 0px;
		border-radius: 5px;
		box-shadow: rgba(14, 14, 44, 0.4) 0px -1px 0px 0px inset;
		cursor: pointer;
		display: inline-flex;
		font-family: inherit;
		font-size: 14px;
		font-weight: 600;
		-webkit-box-pack: center;
		justify-content: center;
		letter-spacing: 0.03em;
		line-height: 1;
		opacity: 1;
		outline: 0px;
		transition: background-color 0.2s ease 0s, opacity 0.2s ease 0s;
		height: 36px;
		padding: 0px 16px;
		background-color: #015DB4;
		color: white;
		width: 100%;
		
	}

	.re_from {
		display: grid;
		grid-template-columns: repeat(1, 1fr);
		grid-gap: 10px;
		margin-bottom: 10px
	}

	.re_laber_input {
		font-size: 14px;
		font-weight: 500;
		color: #fff;
		margin-bottom: 10px;
		display: block
	}

	.re_input {
		height: 36px;
		line-height: 36px;
		width: 100%;
		background-color: #021327;
		border: 1px solid #596775;
		border-radius: 10px;
		color: #fff;
		font-size: 14px;
		font-weight: 500;
		padding: 0 10px;
		word-wrap:break-word;
		word-break:normal; 
	}
	.re_input2 {
		height: 36px;
		line-height: 36px;
		width: 100%;
		background-color: #021327;
		/* border-bottom: 1px solid #596775; */
		border-radius: 10px;
		color: #fff;
		font-size: 14px;
		font-weight: 500;
		padding: 0 10px;
		word-wrap:break-word;
		word-break:normal; 

	}
	.btn_small_1{
		width: calc(100% - 5px);
		height: 40px;
		background:url(../assets/images/blockmoney/btn_small_1.png);
		background-size: cover;
		background-position: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 14px;
		margin: 10px 0;
		max-width: 400px;
	}
	.btn_small_2{
		width: calc(100% - 5px);
		height: 40px;
		background:url(../assets/images/blockmoney/btn_small_2.png);
		background-size: cover;
		background-position: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 14px;
		margin: 10px 0;
		max-width: 400px;
	}
	.btn_medium_2{
		width: calc(100% - 5px);
		height: 40px;
		background:url(../assets/images/blockmoney/btn_medium_2.png);
		background-size: cover;
		background-position: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 14px;
		margin: 10px 0;
		max-width: 400px;
	}
</style>
